import React from 'react'
import Fade from 'react-reveal/Fade';

import './Mission.css'



const Mission = () => {
    return (

<>

        <div className='mission main-menu'>



                <div className="mission-content">
                    <Fade top>
                    <h1>MISSION</h1>
                    </Fade>
                </div>
            </div>
</>
    )
}

export default Mission
