import React from 'react'
import './SASection.css'
import Contact2 from '../contact/Contact2'
import MenusSA from '../menusSA/MenusSA';




const MHSection = () => {
  return (
    <>




<MenusSA />
<Contact2 />

    </>
  )
}

export default MHSection
